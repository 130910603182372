/* src/index.css */

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  display: flex;
  /* Ensure full vertical height */
}

.navbar {
  z-index: 1050;
  /* Higher than most other items on the page */
  position: relative;
  /* Change to 'fixed' if you want it to stay at the top when scrolling */
}

.main-content {
  flex-grow: 1;
  /* Takes up the remaining space */
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 20px;
  /* Optional: Adds some spacing inside the main content */
  padding-top: 20px;
}

.fixed-pagination {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  text-align: center;
}