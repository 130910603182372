/* src/custom.css */
/* This is the custom CSS file for your Saarthi UI project. */


.fixed-width-label {
    min-width: 150px;
    /* Adjust this value based on your design needs */
    border: 1px solid #ced4da;
    /* Adjust the color as per your theme */
    padding: 0.375rem 0.75rem;
    /* Standard padding */
    background-color: #e9ecef;
    /* Standard background */
}

.switch-container {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 0.75rem;
    flex: 1;
}

.right-rounded-button {
    border-top-left-radius: 0 !important;
    /* Ensures the top left corner is square */
    border-bottom-left-radius: 0 !important;
    /* Ensures the bottom left corner is square */
}


/* Clipping the image from the left side */
.trapezoid-img-left {
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

/* Clipping the image from the right side */
.trapezoid-img-right {
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
}

/* Clipping the image from the bottom left */
.trapezoid-img-bottom-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 16% 100%);
}

/* Clipping the image from the top left and bothom right */


.home-banner {
    min-height: 100px;
    /* Changes from fixed height to minimum height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Ensure it's relative if using z-index elsewhere */
}

/* Content styling */
.content-style {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    color: #252627;
    /* Dark charcoal text color */
}

/* Styling for statistic boxes */
.stats-box-style {
    background-color: #FCDEBE;
    /* Soft blue background */
    color: #252627;
    /* Dark charcoal text color */
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    text-align: center;
}

/* Styling for numbers in statistic boxes */
.number-style {
    font-size: 2rem;
    font-weight: bold;
    color: #252627;
    /* Bold red for numbers to attract attention */
}

/* Description text styling in statistic boxes */
.description-style {
    font-size: 1rem;
    color: #252627;
    /* Light green for descriptions to complement the red */
}


/* Ensures that each section has enough space around it */
.container-fluid {
    margin-top: 5px;
    /* Adds space above each container */
    margin-bottom: 10px;
    /* Adds space below each container */
}

/* Additional padding within each container can also help */
.container-fluid>.row {
    padding-top: 5px;
    padding-bottom: 10px;
}

@media (min-width: 992px) {

    /* Adjusts for large screens */
    .container-fluid {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .container-fluid>.row {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.home-banner img {
    width: 100%;
    /* Make the image fill the column */
    height: auto;
    /* Maintain aspect ratio */
}

.banner-text {
    text-align: left;
    /* Centers the text and button horizontally */
}

.banner-text h1,
.banner-text p {
    margin: 0;
    padding: 10px;
}

.sub-text {
    font-size: 1.2rem;
    /* Adjust size as needed */
}

.icon-image {
    width: 100px;
    /* Adjust this value to set the width */
    height: auto;
    /* This keeps the aspect ratio intact */
}

.blockquote {
    font-size: 1.00rem;
    /* Larger font size */
    font-style: italic;
    /* Italicize the quote */
    color: #555;
    /* Dark gray for slight emphasis */
}

.bordered-title {
    border-top: 1px solid #ccc;
    /* Light grey border on top */
    border-bottom: 1px solid #ccc;
    /* Light grey border on bottom */
    padding-top: 15px;
    /* Space between top border and text */
    padding-bottom: 10px;
    /* Space between text and bottom border */
}


.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /* Adds space between items */
}

.feature-item i {
    font-size: 2rem;
    /* Icon size */
    margin-right: 20px;
    /* Space between the icon and the text */
}

/* Values section styling */
.value-icon {
    font-size: 2rem;
    margin-right: 20px;
    vertical-align: middle;
    color: #4B88A2;
    /* Custom color for icons */
}

.value-content h4 {
    margin-bottom: 5px;
    color: #333;
    /* Dark color for headings */
}

.value-content p {
    color: #666;
    /* Lighter color for text */
    margin-bottom: 10px;
}

.custom-button:hover {
    transform: scale(1.05);
    /* Slightly increase size on hover */
    background-color: '#FF6347';
    /* Lighter shade when hovered */
}

.custom-button-portal {
    width: 120px;
    height: 35px;
    border: none;
    /* Assuming you want to keep no borders as per your initial setup */
    color: white;
    border-radius: 0.25rem;
    /* Bootstrap default border-radius, adjust as needed */
}

.custom-button-portal:hover {
    transform: scale(1.05);
    /* Slightly increase size on hover */
}


.table-responsive {
    max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide horizontal scroll if not needed */
    display: block;
    /* Wrap table in a block container to allow for scrolling */
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
    /* This helps in maintaining uniform column widths */
}

.table thead th {
    position: sticky;
    /* Makes the header sticky */
    top: 0;
    /* Keeps the header at the top */
    background-color: #252627;
    /* Ensures the header is not transparent */
    color: #fff;
    /* White text color */
    z-index: 10;
    /* Keeps the header above the tbody content */
}

.table tbody tr {
    display: table-row;
    /* Default display for table rows */
    width: 100%;
    table-layout: fixed;
    /* Ensures table layout is maintained */
}


.blurry-overlay {
    position: relative;
    filter: blur(5px);
    pointer-events: none;
}

.overlay-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    z-index: 1;
}

.overlay-container {
    position: relative;
}

.hidden-content {
    pointer-events: none;
}


.hidden-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none;
    /* For Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}